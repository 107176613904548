export const phone_regex = /^\+?([0-9]{5,})*$/;
export const email_regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const iata_regex = /^[a-zA-Z]{3}$/;
export const digit_regex = /^[0-9]+$/;
export function sanitizeProjectURL(url) {
  if (url) {
    return url
      ?.replace(/[-–`~!@#$%^&*()_|+=?;:",.<>\{\}\[\]\\\/]/gi, "")
      .replace(/\s+/g, " ")
      .replace(" ", "-")
      .split(" ")
      .join("-");
  }
  return "";
}

export function stripContent(str, maxLength) {
  if (str?.length > maxLength) {
    return str.substr(0, maxLength) + "...";
  }
  return str;
}

export function formatArticleDate(dateString) {
  if (dateString) {
    return new Date(dateString).toLocaleDateString();
  }
  return "";
}

export function formatCurrency(value) {
  let CurrencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (!isNaN(value)) return CurrencyFormat.format(value);
  return value;
}

export function formatCurrencyWithoutFraction(value) {
  let CurrencyFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  if (!isNaN(value)) return CurrencyFormat.format(value);
  return value;
}

export function packageImagePlaceholder() {
  return "https://www.centralholidays.com/MyUpload/1023201918184202020PACk850x530mag1.jpg";
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

export function validateIATA(iata) {
  return String(iata).toLowerCase().match(iata_regex);
}

export function validateAmericanPhone(phone) {
  return String(phone)
    .toLowerCase()
    .match(/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/);
}

export function formatAmericanPhoneNumber(number) {
  const cleaned = ("" + number).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function convertAmericanNumberToString(phoneNumber) {
  // Remove all non-digit characters
  return phoneNumber ? String(phoneNumber).replace(/\D/g, "") : null;
}

const getComponent = (components, type) => {
  const component = components.find((c) => c.types.includes(type));
  return component ? component.long_name : "";
};

export function decodeAddressComponent(address) {
  if (address) {
    const streetNumber = getComponent(address, "street_number");
    const streetName = getComponent(address, "route");
    const city = getComponent(address, "locality");
    const state = getComponent(address, "administrative_area_level_1");
    const zip = getComponent(address, "postal_code");
    const country = getComponent(address, "country");
    return {
      streetNumber,
      streetName,
      city,
      state,
      zip,
      country,
    };
  }
  return null;
}

export function convertInputDateToShortDate(date) {
  if (!date) return null;
  const processed_date = new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth(),
    new Date(date).getDate(),
    0,
    0,
    0
  );
  return `${processed_date.toLocaleDateString("en-US", {
    weekday: "short",
  })} ${processed_date.toLocaleDateString("en-US", {
    month: "short",
  })} ${processed_date.getDate()} ${processed_date.getFullYear()}`;
}
