/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";

import axiosconfig from "../../config/axios-config";
import { sanitizeProjectURL } from "../../utility/helper";
import { useRouter } from "next/router";
export default function Footer(props) {
  const [footerData, getFooterData] = useState([]);
  const [pagecontainerData, sePagecontainerData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const router = useRouter();
  const [utmParams, setUtmParams] = useState({});

  useEffect(() => {
    footerDataFn();
    getPagecontainerData();
    getPageData();
  }, []);

  async function getPagecontainerData() {
    const resCon = await axiosconfig.get("website/pagecontainer/mini", {
      params: {
        PageSize: 40,
      },
    });
    const pagecontainerData = await resCon.data.data;
    sePagecontainerData(pagecontainerData);
  }
  async function getPageData() {
    const resPage = await axiosconfig.get("website/pagemodule", {
      params: {
        PageSize: 40,
      },
    });

    const pageData = await resPage.data.data;
    setPageData(pageData);
  }

  async function footerDataFn() {
    const res = await axiosconfig.get("website/footer");

    const alldata = await res.data;

    getFooterData(alldata.data[0]);
  }

  let PageShow = [];
  let bottomFooterPages = pageData?.filter(
    (page) => page.isActive && page.isExternalLink
  );
  pageData.forEach((page) => {
    if (page.isFooterPage == true) {
      PageShow.push(page);
    }
  });

  // Use effect to handle utm parameters
  useEffect(() => {
    const {
      utm_source,
      utm_Source,
      Utm_Source,
      utm_medium,
      utm_Medium,
      Utm_Medium,
      utm_campaign,
      utm_Campaign,
      Utm_Campaign,
      utm_term,
      utm_Term,
      Utm_Term,
      utm_content,
      utm_Content,
      Utm_Content,
    } = router.query;
    setUtmParams({
      utm_source: utm_source || utm_Source || Utm_Source,
      utm_medium: utm_medium || utm_Medium || Utm_Medium,
      utm_campaign: utm_campaign || utm_Campaign || Utm_Campaign,
      utm_term: utm_term || utm_Term || Utm_Term,
      utm_content: utm_content || utm_Content || Utm_Content,
    });
  }, [router.query]);

  return (
    <footer id="colophon" className="site-footer footer-primary">
      <div className="top-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <aside className="widget widget_text">
                <h3 className="widget-title">{footerData.companyName}</h3>
                <div className="textwidget widget-text">
                  <p
                    dangerouslySetInnerHTML={{ __html: footerData.description }}
                  ></p>{" "}
                  <br />
                  <span>
                    <img
                      alt="Central Holidays"
                      src={footerData.logoURL}
                      width="280"
                      height="auto"
                    />
                  </span>
                </div>
              </aside>
            </div>

            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Quick Links</h3>
                <ul className="row mx-0">
                  <li className="col-12 order-1">
                    {utmParams.utm_source ||
                    utmParams.utm_medium ||
                    utmParams.utm_campaign ||
                    utmParams.utm_term ||
                    utmParams.utm_content ? (
                      <Link
                        href={{
                          pathname: "/",
                          query: {
                            utm_source: utmParams.utm_source,
                            utm_medium: utmParams.utm_medium,
                            utm_campaign: utmParams.utm_campaign,
                            utm_term: utmParams.utm_term,
                            utm_content: utmParams.utm_content,
                          },
                        }}
                      >
                        - Home
                      </Link>
                    ) : (
                      <Link href="/">- Home</Link>
                    )}
                  </li>
                  <div style={{ clear: "both" }}></div>
                  {PageShow.slice(0, PageShow.length / 2).map((page) => {
                    return (
                      <Fragment key={page.id}>
                        <li className={`col-12 order-${page.order}`}>
                          {page.isActive == true ? (
                            pagecontainerData.map((container, index) => {
                              if (
                                page.id === container.pageModuleId &&
                                container.pageSubModuleId.length < 1
                              ) {
                                if (container.isExtrnalLink == true) {
                                  return (
                                    <Fragment key={index}>
                                      -
                                      {utmParams.utm_source ||
                                      utmParams.utm_medium ||
                                      utmParams.utm_campaign ||
                                      utmParams.utm_term ||
                                      utmParams.utm_content ? (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={{
                                            pathname: `${container.externalLinkUrl}`,
                                            query: {
                                              utm_source: utmParams.utm_source,
                                              utm_medium: utmParams.utm_medium,
                                              utm_campaign:
                                                utmParams.utm_campaign,
                                              utm_term: utmParams.utm_term,
                                              utm_content:
                                                utmParams.utm_content,
                                            },
                                          }}
                                        >
                                          {page.name}
                                        </a>
                                      ) : (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`${container.externalLinkUrl}`}
                                        >
                                          {page.name}
                                        </a>
                                      )}
                                    </Fragment>
                                  );
                                } else if (container.isPdfUrl == true) {
                                  return (
                                    <Fragment key={index}>
                                      -{" "}
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${container.pdfUrl}`}
                                      >
                                        {page.name}
                                      </a>
                                    </Fragment>
                                  );
                                } else {
                                  return (
                                    <Fragment key={index}>
                                      {utmParams.utm_source ||
                                      utmParams.utm_medium ||
                                      utmParams.utm_campaign ||
                                      utmParams.utm_term ||
                                      utmParams.utm_content ? (
                                        <Link
                                          href={{
                                            pathname: `/${
                                              container.pageSEO?.pageURL?.length
                                                ? container.pageSEO.pageURL
                                                : "/"
                                            }`,
                                            query: {
                                              utm_source: utmParams.utm_source,
                                              utm_medium: utmParams.utm_medium,
                                              utm_campaign:
                                                utmParams.utm_campaign,
                                              utm_term: utmParams.utm_term,
                                              utm_content:
                                                utmParams.utm_content,
                                            },
                                          }}
                                        >
                                          <a>- {page.name}</a>
                                        </Link>
                                      ) : (
                                        <Link
                                          href={`/${
                                            container.pageSEO?.pageURL?.length
                                              ? container.pageSEO.pageURL
                                              : "/"
                                          }`}
                                        >
                                          <a>- {page.name}</a>
                                        </Link>
                                      )}
                                    </Fragment>
                                  );
                                }
                              }
                            })
                          ) : (
                            <a style={{ cursor: "pointer" }}>- {page.name}</a>
                          )}{" "}
                        </li>
                        <div style={{ clear: "both" }}></div>
                      </Fragment>
                    );
                  })}
                </ul>
              </aside>
            </div>

            <div className="col-lg-3 col-md-6">
              <aside className="widget widget_recent_post">
                <h3 className="widget-title">Quick Links</h3>
                <div className="textwidget widget-text">
                  <ul className="row mx-0">
                    {PageShow.slice(PageShow.length / 2).map((page) => {
                      return (
                        <Fragment key={page.id}>
                          <li className={`col-12 order-${page.order}`}>
                            {page.isActive == true ? (
                              pagecontainerData.map((container, index) => {
                                if (
                                  page.id === container.pageModuleId &&
                                  container.pageSubModuleId.length < 1
                                ) {
                                  if (container.isExtrnalLink == true) {
                                    return (
                                      <Fragment key={index}>
                                        -
                                        {utmParams.utm_source ||
                                        utmParams.utm_medium ||
                                        utmParams.utm_campaign ||
                                        utmParams.utm_term ||
                                        utmParams.utm_content ? (
                                          <a
                                            rel="noreferrer"
                                            href={{
                                              pathname: `${container.externalLinkUrl}`,
                                              query: {
                                                utm_source:
                                                  utmParams.utm_source,
                                                utm_medium:
                                                  utmParams.utm_medium,
                                                utm_campaign:
                                                  utmParams.utm_campaign,
                                                utm_term: utmParams.utm_term,
                                                utm_content:
                                                  utmParams.utm_content,
                                              },
                                            }}
                                          >
                                            {page.name}
                                          </a>
                                        ) : (
                                          <a
                                            rel="noreferrer"
                                            href={`${container.externalLinkUrl}`}
                                          >
                                            {page.name}
                                          </a>
                                        )}
                                      </Fragment>
                                    );
                                  } else if (container.isPdfUrl == true) {
                                    return (
                                      <Fragment key={index}>
                                        -
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={`${container.pdfUrl}`}
                                        >
                                          {page.name}
                                        </a>
                                      </Fragment>
                                    );
                                  } else {
                                    return (
                                      <Fragment key={index}>
                                        {utmParams.utm_source ||
                                        utmParams.utm_medium ||
                                        utmParams.utm_campaign ||
                                        utmParams.utm_term ||
                                        utmParams.utm_content ? (
                                          <Link
                                            href={{
                                              pathname: `/${
                                                container.pageSEO?.pageURL
                                                  ?.length > 0
                                                  ? container.pageSEO.pageURL
                                                  : "/"
                                              }`,
                                              query: {
                                                utm_source:
                                                  utmParams.utm_source,
                                                utm_medium:
                                                  utmParams.utm_medium,
                                                utm_campaign:
                                                  utmParams.utm_campaign,
                                                utm_term: utmParams.utm_term,
                                                utm_content:
                                                  utmParams.utm_content,
                                              },
                                            }}
                                          >
                                            <a>- {page.name}</a>
                                          </Link>
                                        ) : (
                                          <Link
                                            href={`/${
                                              container.pageSEO?.pageURL
                                                ?.length > 0
                                                ? container.pageSEO.pageURL
                                                : "/"
                                            }`}
                                          >
                                            <a>- {page.name}</a>
                                          </Link>
                                        )}
                                      </Fragment>
                                    );
                                  }
                                }
                              })
                            ) : (
                              <a style={{ cursor: "pointer" }}>- {page.name}</a>
                            )}{" "}
                          </li>
                          <div style={{ clear: "both" }}></div>
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      <div className="buttom-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="footer-menu">
                <ul>
                  {bottomFooterPages?.map((page) => {
                    return (
                      <Fragment key={page.id}>
                        <li>
                          {utmParams.utm_source ||
                          utmParams.utm_medium ||
                          utmParams.utm_campaign ||
                          utmParams.utm_term ||
                          utmParams.utm_content ? (
                            <a
                              className="fs-14"
                              rel="noreferrer"
                              href={`${page.externalLink}?utm_source=${utmParams.utm_source}&utm_medium=${utmParams.utm_medium}&utm_campaign=${utmParams.utm_campaign}&utm_term=${utmParams.utm_term}&utm_content=${utmParams.utm_content}`}
                              target="_blank"
                            >
                              {page.name}
                            </a>
                          ) : (
                            <a
                              className="fs-14"
                              rel="noreferrer"
                              href={`${page.externalLink}`}
                              target="_blank"
                            >
                              {page.name}
                            </a>
                          )}
                        </li>
                      </Fragment>
                    );
                  })}
                  {/* <li>
                    {" "}
                    <a
                      href="https://portal.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Agent Portal{" "}
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://booking.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Passenger Portal
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://supplier.centralholidays.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Supplier Portal
                    </a>{" "}
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-md-2 text-center"></div>
            <div className="col-md-5">
              <div className="copy-right text-right fs-14">
                © Copyright Central Holidays {new Date().getFullYear()}. All
                Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
